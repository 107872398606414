import { Container, checkMoney, isMoney } from '@ivorobioff/shared';
import { Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Observable, combineLatest, concat } from 'rxjs';
import AccountService from '../../services/AccountService';
import PreferenceService, { calcExchangedAmount } from '../../services/PreferenceService';
import PlanService from '../../services/PlanService';
import { UserService } from '../../services/UserService';
import { Controller, useForm, useWatch } from 'react-hook-form';
import Plan from '../../models/Plan';
import Account from '../../models/Account';
import Preference from '../../models/Preference';
import { Alert } from '@material-ui/lab';
import { fixedAmount } from '../../random/utils';

interface AddExpensesViewProps {
  container: Container;
}

interface FormData {
  account: string;
  plan: string;
  amount: string;
  note?: string;
}

export default function AddExpensesView({ container }: AddExpensesViewProps) {

  const accountService = container.get(AccountService);
  const preferenceService = container.get(PreferenceService);
  const userService = container.get(UserService);
  const planService = container.get(PlanService);

  const [accounts, setAccounts] = useState<Account[]>([]);
  const [plans, setPlans] = useState<Plan[]>([]);
  const [preference, setPreference] = useState<Preference>();
  const [applyExchangeRate, setApplyExchangeRate] = useState<boolean>(false);
  const [exchangedAmount, setExchangedAmount] = useState<string>('');
  const [success, setSuccess] = useState<boolean | null>(null);
  const [loading, setLoading] = useState(false);

  const { resetField, control, handleSubmit, formState: { isDirty, isValid }, setValue } = useForm<FormData>({
    mode: 'onChange',
    defaultValues: {
      amount: '',
      note: '',
      account: '',
      plan: ''
    }
  });

  useEffect(() => {
    combineLatest([accountService.getAll(), userService.get()]).subscribe(([accounts, user]) => {
      setAccounts(accounts);
      setValue('account', user.activeAccountId);
    });
  }, [accountService, userService, setValue]);

  const selectedAccountId = useWatch({ control, name: 'account' });
  const amount = useWatch({ control, name: 'amount' })

  useEffect(() => {
    if (selectedAccountId) {
      combineLatest([planService.getAll(selectedAccountId), preferenceService.get(selectedAccountId)])
        .subscribe(([plans, preference]) => {
          setPlans(plans);
          setPreference(preference);
          setValue('plan', plans[0]?.id || '');
        });

      setApplyExchangeRate(false);
      setExchangedAmount('');
    }
  }, [selectedAccountId, planService, preferenceService, setValue]);

  useEffect(() => {
    if (!applyExchangeRate) {
      return;
    }

    if (isMoney(amount)) {
      setExchangedAmount(calcExchangedAmount(amount, preference));
    } else {
      setExchangedAmount('');
    }
  }, [preference, amount, applyExchangeRate]);

  const formProcessor = (data: FormData) => {
    setSuccess(null);
    setLoading(true);

    const selectedPlanId = data.plan;
    const accountId = data.account;
    const note = data.note;
    const selectedPlan = plans.find(plan => plan.id === selectedPlanId)!;
    const amount = parseFloat(applyExchangeRate ? exchangedAmount : data.amount);

    const tasks: Observable<unknown>[] = [];

    let plannedAmount = selectedPlan.plannedAmount;
    let availableAmount = selectedPlan.availableAmount;
    let remainingAmount = availableAmount - amount;

    if (remainingAmount < 0) {
      const diffAmount = Math.abs(remainingAmount);
      plannedAmount += diffAmount;

      tasks.push(planService.updateAmounts(selectedPlanId, {
        plannedAmount: fixedAmount(plannedAmount),
        availableAmount: fixedAmount(availableAmount + diffAmount),
        note,
        accountId
      }));

      remainingAmount = 0;
    }

    tasks.push(planService.updateAmounts(selectedPlanId, {
      availableAmount: fixedAmount(remainingAmount),
      accountId,
      note
    }));

    concat(...tasks).subscribe(() => {
      setPlans(plans.map(plan => plan.id === selectedPlanId
        ? { ...plan, availableAmount: fixedAmount(remainingAmount), plannedAmount: fixedAmount(plannedAmount) }
        : plan));
      resetField('amount');
      resetField('note');

      setApplyExchangeRate(false);
      setExchangedAmount('');
      setSuccess(true);
      setLoading(false);
    }, e => {
      setSuccess(false);
      setLoading(false);
      console.log(e);
    });
  };

  const closeAlert = () => setSuccess(null);
  const canSubmit = !isDirty || !isValid || loading;

  return <Box marginTop={3} paddingX={1}>
    <Paper variant="outlined">
      <Box padding={2}>
        <Typography component="h2" variant="h6" color="primary">Add Expense</Typography>
        <br />
        <form onSubmit={handleSubmit(formProcessor)}>
          <Controller name="account" control={control}
            rules={{
              required: `It's required!`
            }}
            render={({ field, fieldState: { invalid, error } }) =>
              <FormControl fullWidth error={invalid}>
                <InputLabel>Account</InputLabel>
                <Select {...field}>
                  {accounts.map(account => <MenuItem key={account.id} value={account.id}>{account.name}</MenuItem>)}
                </Select>
                {invalid && <FormHelperText>{error?.message}</FormHelperText>}
              </FormControl>} />

          <Box mb={1} />

          <Controller name="plan" control={control}
            rules={{
              required: `It's required!`
            }}
            render={({ field: { onChange, value }, fieldState: { invalid, error } }) =>
              <FormControl fullWidth error={invalid}>
                <InputLabel>Plan</InputLabel>
                <Select value={value} onChange={onChange}>
                  {value && plans.some(plan => plan.id === value)
                    ? plans.map(plan => <MenuItem key={plan.id} value={plan.id}>{plan.name}</MenuItem>)
                    : <MenuItem value={value}></MenuItem>}
                </Select>
                {invalid && <FormHelperText>{error?.message}</FormHelperText>}
              </FormControl>} />
          <Box mb={1} />
          <Controller name="amount" control={control}
            rules={{
              validate: checkMoney
            }}
            render={({ field, fieldState: { invalid, error } }) =>
              <TextField type="number"
                label={`Amount ${applyExchangeRate ? `(${preference?.spendingCurrency || 'Spending Currency'})` : ''}`}
                fullWidth
                error={invalid}
                helperText={error?.message}
                {...field} />} />
          {preference?.exchangeRate && (<>
            {applyExchangeRate && <>
              <Box mb={1} />
              <TextField type="text"
                value={exchangedAmount}
                label={`Amount (${preference?.incomeCurrency || 'Income Currency'})`}
                fullWidth
                disabled={true} />
            </>}
            <Box mb={1} />
            <FormControl fullWidth>
              <FormControlLabel control={
                <Checkbox color="primary" checked={applyExchangeRate} onChange={e => setApplyExchangeRate(e.target.checked)} />
              } label={`Use exchange rate: ${preference.exchangeRate}`} />
            </FormControl>
          </>)}
          <Box mb={1} />

          <Controller name="note" control={control}
            render={({ field, fieldState: { error, invalid } }) =>
              <TextField label="Note" multiline fullWidth {...field} error={invalid} helperText={error?.message} />} />

          {success === true && <Box marginTop={3}><Alert onClose={closeAlert} severity="success">The expense has been successfully added!</Alert></Box>}
          {success === false && <Box marginTop={3}><Alert onClose={closeAlert} severity="error">The expense has not been added due to error!</Alert></Box>}

          <Box textAlign="right" marginTop={3}>
            <Box component="div" position="relative" display="inline-block">
              <Button variant="contained"
                color="primary"
                type="submit"
                disabled={canSubmit}>Submit</Button>
              {loading && <Box
                position="absolute"
                top="0"
                marginTop={0.8}
                marginLeft={-1.3}
                left="50%"><CircularProgress size={24} /></Box>}
            </Box>
          </Box>
        </form>
      </Box>
    </Paper>
  </Box>;
}