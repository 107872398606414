import { Observable } from "rxjs";
import { HttpCommunicator, Container } from "@ivorobioff/shared";
import { Transfer } from '../models/Operation';

export class OperationService {

  private http: HttpCommunicator;

  constructor(container: Container) {
    this.http = container.get('https');
  }

  public transfer(data: Transfer): Observable<void> {
    return this.http.post('/operations/transfer', data);
  }
}