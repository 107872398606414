import Plan, { PlanAmountsToUpdate, PlanToCreate, PlanToUpdate } from "../models/Plan";
import { Observable } from "rxjs";
import { HttpCommunicator, Container } from "@ivorobioff/shared";

class PlanService {

  private http: HttpCommunicator;

  constructor(container: Container) {
    this.http = container.get('https');
  }

  getAll(accountId?: string): Observable<Plan[]> {
    return this.http.get(`/plans${accountId ? `?accountId=${accountId}` : ''}`);
  }

  updateAmounts(id: string, amounts: PlanAmountsToUpdate): Observable<any> {
    return this.http.patch('/plans/' + id, amounts);
  }

  update(id: string, updates: PlanToUpdate): Observable<any> {
    return this.http.patch('/plans/' + id, updates);
  }

  create(plan: PlanToCreate): Observable<Plan> {
    return this.http.post('/plans', plan);
  }

  remove(id: string): Observable<any> {
    return this.http.delete('/plans/' + id);
  }

}

export default PlanService